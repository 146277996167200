import React from "react";
import CheckAuthExpiry from "Tasks/CheckAuthExpiry.js";
import Container from "Components/Container.js";
import ErrorGate from "Components/ErrorGate.js";
import RefreshAuth from "Tasks/RefreshAuth.js";
import RefreshRegistration from "Tasks/RefreshRegistration.js";
import String from "Components/String.js";
import Ui from "Ui/Ui.js";
import withAuth from "Hoc/withAuth.js";
import withOrg from "Hoc/withOrg.js";
import withTheme from "Hoc/withTheme.js";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {Button} from "@heron-web/material";
import * as Sentry from "@sentry/react";
import TagManager from "@sooro-io/react-gtm-module";
import withCookieConsent from "Hoc/withCookieConsent";

/**
 * Main
 * 
 * @package HOPS
 * @subpackage App
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Main extends React.PureComponent {

	/**
	 * Auth ping timer
	 * 
	 * @type {Integer|null}
	 */
	authPingTimer = null;

	/**
	 * Auth expiry timer
	 * 
	 * @type {Integer|null}
	 */
	authExpiryTimer = null;

	/**
	 * State
	 *
	 * @type {Object}
	 */
	state = {

		/**
		 * Registration data fetch error
		 * 
		 * @type {Error|null}
		 */
		error: null,

		/**
		 * Ready to render?
		 * 
		 * @type {Boolean}
		 */
		ready: false

	};


	/**
	 * Component mounted.
	 * 
	 * We need to get our registration.
	 * 
	 * @return {void}
	 */
	async componentDidMount() {

		this.stripUriSlashes();

		this.setSentryUser();

		this.updateGoogleFonts();

		try {
			await RefreshRegistration();
		}
		catch (e) {
			this.setState({error: e, ready: true});
			return;
		}

		await CheckAuthExpiry();

		try {
			await RefreshAuth();
		}
		catch (e) {
			/*
			 * Auth refresh failed but this is not neccessarily an issue
			 * User will just see errors if using an expired session.
			 */
		}

		this.authPingTimer = setInterval(RefreshAuth, (15 * 60 * 1000));
		this.authExpiryTimer = setInterval(CheckAuthExpiry, (5 * 60 * 1000));

		this.setState({ready: true});

	}


	/**
	 * Component unmounting.
	 * 
	 * @return {void}
	 */
	componentWillUnmount() {

		if (this.authPingTimer) {
			clearInterval(this.authPingTimer);
			this.authPingTimer = null;
		}

		if (this.authExpiryTimer) {
			clearInterval(this.authExpiryTimer);
			this.authExpiryTimer = null;
		}

	}


	/**
	 * Component updated.
	 *
	 * @param {Object} prevProps
	 * @return {void}
	 */
	componentDidUpdate(prevProps) {

		if (this.props.authed !== prevProps.authed) {
			this.setSentryUser();
		}

		if (this.routeChanged(prevProps)) {
			this.stripUriSlashes();
		}

		if (this.props.theme.FontFamily !== prevProps.theme.FontFamily) {
			this.updateGoogleFonts();
		}

		if (this.props.org?.GoogleTagManagerId !== prevProps.org?.GoogleTagManagerId) {
			this.initGoogleTagManager();
		}

		if (this.props.cookieConsentToOptional !== prevProps.cookieConsentToOptional) {

			this.updateGoogleTagManagerDataLayer("consent", "update", {
				ad_user_data: this.props.cookieConsentToOptional ? "granted" : "denied",
				ad_personalization: this.props.cookieConsentToOptional ? "granted" : "denied",
				ad_storage: this.props.cookieConsentToOptional ? "granted" : "denied",
				analytics_storage: this.props.cookieConsentToOptional ? "granted" : "denied"
			});

		}

	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Sentry.ErrorBoundary fallback={<ErrorGate />}>
				{(this.props.org ? this.renderUi() : null)}
				{(this.state.error ? ((this.state.error?.response?.status !== 404) ? this.constructor.renderError() : this.constructor.render404()) : null)}
			</Sentry.ErrorBoundary>
		);
	}


	/**
	 * Get whether the route has changed from a "previous" props object.
	 * 
	 * @param {Object} prevProps
	 * @return {Boolean}
	 */
	routeChanged(prevProps) {
		const pathname = prevProps?.location?.pathname;
		const search = prevProps?.location?.search;
		const uri = (pathname !== this.props.location.pathname);
		const qs = (search !== this.props.location.search);
		return (uri || qs);
	}


	/**
	 * Strip excess slashes from our URI.
	 * 
	 * @return {void}
	 */
	stripUriSlashes() {
		if (!["", "/"].includes(this.props.location.pathname)) {
			const pathname = this.props.location.pathname.replace(/(\/+)/, "/").replace(/(\/+)$/, "");
			if (pathname !== this.props.location.pathname) {
				const query = this.props.location.search;
				this.props.history.replace(`${pathname}${query}`);
			}
		}
	}


	/**
	 * Render the UI.
	 *
	 * @return {ReactNode}
	 */
	renderUi() {
		return <Ui ready={this.state.ready} />;
	}


	/**
	 * Set the Sentry user details.
	 * 
	 * @return {void}
	 */
	setSentryUser() {
		if (!this.props.authed) Sentry.setUser(null);
		else Sentry.setUser({username: this.props.auth.Username});
	}


	/**
	 * Update our Google fonts using our theme.
	 *
	 * Removes existing fonts then adds a new `link` using our theme.
	 * 
	 * @return {void}
	 */
	updateGoogleFonts() {

		const selector = "data-hops-gfont";
		const gfonts = Array.from(document.querySelectorAll(`[${selector}]`));

		gfonts.forEach(gfont => {
			gfont.parentNode.removeChild(gfont);
		});


		if (this.props.theme.FontFamilyGoogle) {
			const link = document.createElement("link");
			link.href = `https://fonts.googleapis.com/css2?family=${encodeURI(this.props.theme.FontFamily)}&display=swap`;
			link.rel = "stylesheet";
			link.setAttribute(selector, true);
			document.head.appendChild(link);
		}

	}


	/**
	 * Initialise Google Tag Manager
	 * 
	 * @return {void}
	 */
	initGoogleTagManager() {

		if (this.props.org?.GoogleTagManagerId) {

			/** Helper function - GTM expects an Arguments object in the dataLayer array */

			// eslint-disable-next-line func-style, no-inner-declarations
			function gtag() {

				// eslint-disable-next-line prefer-rest-params
				window["dataLayer"].push(arguments);

			}

			/** 
			 * Push current consents on to the datalayer as defaults
			 * This is necessary because the user may have visited in the past and already consented.
			 */
			window["dataLayer"] = window["dataLayer"] || [];

			gtag("consent", "default", {
				ad_user_data: this.props.cookieConsentToOptional ? "granted" : "denied",
				ad_personalization: this.props.cookieConsentToOptional ? "granted" : "denied",
				ad_storage: this.props.cookieConsentToOptional ? "granted" : "denied",
				analytics_storage: this.props.cookieConsentToOptional ? "granted" : "denied",
				wait_for_update: 500
			});

			/** Init Google Tag Manager with the new Id */
			TagManager.initialize({gtmId: this.props.org.GoogleTagManagerId});

		}

	}


	/**
	 * Update Google Tag Manager datalayer
	 * 
	 * @return {void}
	 */
	updateGoogleTagManagerDataLayer() {

		// eslint-disable-next-line prefer-rest-params
		TagManager.dataLayer({dataLayer: arguments});

	}


	/**
	 * Render the error state.
	 *
	 * @return {ReactNode}
	 */
	static renderError() {
		return (
			<Container px={1} py={1}>
				<String bold={true} str="We're sorry – something's gone wrong" variant="h6" />
				<String color="textSecondary" str="We couldn't load the site." />
				<String color="textSecondary" str="Please check you're online and then try again." />
				<div>
					<Button label="Refresh" onClick={() => window.location.reload()} />
				</div>
			</Container>
		);
	}


	/**
	 * Render the 404 state.
	 * 
	 * @return {ReactNode}
	 */
	static render404() {
		return (
			<Container px={1} py={1}>
				<String bold={true} str="Routing error" variant="h6" />
				<String color="textSecondary" str="There's no content on this site." />
				<String color="textSecondary" str="Please check the URL you're visiting." />
			</Container>
		);
	}

}

export default connect(({error}) => ({error}))(withAuth(withCookieConsent(withOrg(withRouter(withTheme(Main))))));
