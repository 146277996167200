/**
 * Reducer
 *
 * @package HOPS
 * @subpackage Hoc
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Reducer {

	/**
	 * Arguments.
	 *
	 * @param {Object} state
	 * @param {String|null} options.args
	 * @return {Object}
	 */
	static args(state, {args}) {
		return {...state, args};
	}


	/**
	 * Authenticate.
	 * 
	 * @param {Object} state
	 * @param {Object} options.auth
	 * @return {Object}
	 */
	static auth(state, {auth}) {
		return {...state, auth};
	}


	/**
	 * Add an item to the checkout basket.
	 *
	 * @param {Object} state
	 * @param {Array<BasketItem>} options.items
	 * @return {Object}
	 */
	static basketAdd(state, {items}) {
		return {
			...state,
			checkoutBasket: [...state.checkoutBasket, ...items]
		};
	}


	/**
	 * Remove an item from the checkout basket.
	 *
	 * @param {Object} state
	 * @param {Array<String>} options.uuids
	 * @return {Object}
	 */
	static basketRemove(state, {uuids}) {
		return {
			...state,
			checkoutBasket: state.checkoutBasket.filter(i => !uuids.includes(i.Uuid))
		};
	}


	/**
	 * Update a basket item by UUID.
	 *
	 * Note that this actually replaces the matching basket item 
	 * with the incoming new item as items are meant to be immutable.
	 *
	 * @param {Object} state
	 * @param {String} options.uuid
	 * @param {BasketItem} options.item
	 * @return {Object}
	 */
	static basketUpdate(state, {uuid, item}) {

		const i = state.checkoutBasket.find(i => (i.Uuid === uuid));
		if (!i) return {...state};

		const checkoutBasket = [...state.checkoutBasket];
		checkoutBasket[checkoutBasket.indexOf(i)] = item;
		return {...state, checkoutBasket};

	}


	/**
	 * Checkout basket reducer.
	 *
	 * Replaces the basket contents.
	 *
	 * @param {Object} state
	 * @param {Array<BasketItem>} options.checkoutBasket
	 * @return {Object}
	 */
	static checkoutBasket(state, {checkoutBasket}) {
		return {...state, checkoutBasket};
	}


	/**
	 * Checkout basket addition data reducer.
	 *
	 * @param {Object} state
	 * @param {Object|null} options.checkoutBasketAdditionData
	 * @return {Object}
	 */
	static checkoutBasketAdditionData(state, {checkoutBasketAdditionData}) {
		return {...state, checkoutBasketAdditionData};
	}


	/**
	 * Checkout basket discounts data reducer.
	 *
	 * @param {Object} state
	 * @param {Array<Object>} options.checkoutBasketDiscounts
	 * @return {Object}
	 */
	static checkoutBasketDiscounts(state, {checkoutBasketDiscounts}) {
		return {...state, checkoutBasketDiscounts};
	}


	/**
	 * Checkout basket identity reducer.
	 *
	 * Sets the basket ID and expiration time.
	 *
	 * @param {Object} state
	 * @param {Integer|null} options.Id
	 * @param {Integer|null} options.Expiry
	 * @return {Object}
	 */
	static checkoutBasketIdentity(state, {Id, Expiry}) {
		return {...state, checkoutBasketId: Id, checkoutBasketExpiry: Expiry};
	}


	/**
	 * Checkout basket loading state reducer.
	 *
	 * @param {Object} state
	 * @param {Boolean} options.checkoutBasketLoading
	 * @return {Object}
	 */
	static checkoutBasketLoading(state, {checkoutBasketLoading}) {
		return {...state, checkoutBasketLoading};
	}


	/**
	 * Checkout order vouchers changed.
	 *
	 * @param {Object} state
	 * @param {Array} options.checkoutOrderVouchers
	 * @return {Object}
	 */
	static checkoutOrderVouchers(state, {checkoutOrderVouchers}) {
		return {...state, checkoutOrderVouchers};
	}


	/**
	 * Checkout redirect URI target changed.
	 *
	 * @param {Object} state
	 * @param {String} options.checkoutRedirectUri
	 * @return {Object}
	 */
	static checkoutRedirectUri(state, {checkoutRedirectUri}) {
		return {...state, checkoutRedirectUri};
	}


	/**
	 * Consent for optional cookies given/withdrawn
	 *
	 * @param {Object} state
	 * @param {String} options.cookieConsentToOptional
	 * @return {Object}
	 */
	static cookieConsentToOptional(state, {cookieConsentToOptional}) {
		return {...state, cookieConsentToOptional};
	}


	/**
	 * Update enabled features.
	 * 
	 * @param {Object} state
	 * @param {Array} options.features Feature names to enable
	 * @return {Object}
	 */
	static features(state, {features}) {
		return {...state, features};
	}


	/**
	 * Update the features offered by the API.
	 *
	 * @param {Object} state
	 * @param {Object} options.featuresAvailable Refer to `state`
	 * @return {Object}
	 */
	static featuresAvailable(state, {featuresAvailable}) {
		return {...state, featuresAvailable};
	}


	/**
	 * Update the force-enabled features list.
	 *
	 * @param {Object} state
	 * @param {Array} options.featuresForced Feature names to enable
	 * @return {Object}
	 */
	static featuresForced(state, {featuresForced}) {
		return {...state, featuresForced};
	}


	/**
	 * History reducer.
	 *
	 * Force a re-render by updating the route key.
	 *
	 * @param {Object} state
	 * @return {Object}
	 */
	static history(state) {
		return {...state, rkey: (state.rkey + 1)};
	}


	/**
	 * Loading reducer.
	 * 
	 * @param {Object} state
	 * @param {Boolean} options.loading
	 * @return {Object}
	 */
	static loading(state, {loading}) {
		return {...state, loading};
	}


	/**
	 * Registration reducer.
	 *
	 * Updates the registration using the given value objects.
	 *
	 * @param {Object} state
	 * @param {Object} options.org
	 * @param {Array<Object>} options.paymentMethods
	 * @param {Object} options.strings
	 * @param {Object} options.theme
	 * @return {Object}
	 */
	static registration(state, {org, paymentMethods, strings, theme}) {
		return {...state, org, paymentMethods, strings, theme};
	}


	/**
	 * Seat reservation dialog reducer.
	 *
	 * @param {Object} state
	 * @param {?String} options.seatReservationDialog
	 * @return {Object}
	 */
	static seatReservationDialog(state, {seatReservationDialog}) {
		return {...state, seatReservationDialog};
	}


	/**
	 * Material theme reducer.
	 * 
	 * @param {Object} state
	 * @param {String} options.themeMaterial Theme name
	 * @return {Object}
	 */
	static themeMaterial(state, {themeMaterial}) {
		return {...state, themeMaterial};
	}


	/**
	 * Update ticket system registration data.
	 *
	 * @param {Object} state
	 * @param {Object} options.data
	 * @return {Object}
	 */
	static ticketsRegistration(state, {data}) {
		return {
			...state,
			ticketsRegistration: {
				...state.ticketsRegistration,
				...data
			}
		};
	}


	/**
	 * Tickets system selection reducer.
	 *
	 * @param {Object} state
	 * @param {Object} options.data
	 * @return {Object}
	 */
	static ticketsSelection(state, {data}) {
		return {
			...state,
			ticketsSelection: {
				...state.ticketsSelection,
				...data
			}
		};
	}


	/**
	 * Tickets view header image configuration reducer.
	 *
	 * @param {Object} state
	 * @param {String} options.alt
	 * @param {String} options.src
	 * @return {Object}
	 */
	static ticketsViewHeaderImg(state, {alt, src}) {
		return {
			...state,
			ticketsViewHeaderImg: {alt, src}
		};
	}

	/**
	 * Update shares system registration data.
	 *
	 * @param {Object} state
	 * @param {Object} options.data
	 * @return {Object}
	 */
	static sharesRegistration(state, {data}) {
		return {
			...state,
			sharesRegistration: {
				...state.sharesRegistration,
				...data
			}
		};
	}

	/**
	 * Generic reducer to merge arbitrary values.
	 * 
	 * @param {Object} state
	 * @param {Object} options.props
	 * @return {Object}
	 */
	static set(state, {props}) {
		return {...state, ...props};
	}


	/**
	 * State reducer.
	 *
	 * Calls the `Reducer` method given by the `type` property of 
	 * the `action` parameter, passing the state object and 
	 * the value of `action` itself, to obtain the new state.
	 *
	 * @param {Object} state
	 * @param {Object} action
	 * @return {Object}
	 */
	static reduce(state, action) {
		if (Reducer.hasOwnProperty(action.type)) {
			state = Reducer[action.type](state, action);
		}
		return state;
	}

}

export default Reducer.reduce;
