import Reducer from "./Reducer.js";
import State from "./State.js";
import storage from "redux-persist/lib/storage";
import {applyMiddleware, createStore} from "redux";
import {composeWithDevTools} from "redux-devtools-extension";
import {persistReducer, persistStore} from "redux-persist";
import {createStateSyncMiddleware, initMessageListener} from "redux-state-sync";

/**
 * `redux-persist`
 */
const rpc = {
	storage,
	key: "app",
	// eslint-disable-next-line require-await
	async migrate(state) {
		if (state.themeMaterial === "light") {
			state.themeMaterial = "auto";
		}
		return state;
	},
	whitelist: [
		"api",
		"args",
		"auth",
		"checkoutBasket",
		"checkoutBasketDiscounts",
		"checkoutBasketId",
		"checkoutBasketExpiry",
		"checkoutRedirectUri",
		"cookieConsentToOptional",
		"featuresForced",
		"themeMaterial"
	]
};

/**
 * `redux-state-sync`
 */
const rss = {
	blacklist: ["history", "loading", "seatReservationDialog", "persist/PERSIST", "persist/REHYDRATE"],
	broadcastChannelOption: {webWorkerSupport: false}
};

/**
 * Create our store
 */
const store = createStore(
	persistReducer(rpc, Reducer),
	State,
	composeWithDevTools(applyMiddleware(createStateSyncMiddleware(rss)))
);

/**
 * Create our store persistor
 */
const persistor = persistStore(store);

/**
 * Initialise synchronisation listener
 */
initMessageListener(store);

/**
 * Expose the store on `window` to aid development/debugging
 */
window.__hops_redux_store = store;

export default store;
export {store as Store, persistor as StorePersistor};
