import React from "react";

import Button from "Components/Button";
import Flex from "Components/Flex";
import Link from "Components/Link";
import String from "Components/String";

import Container from "@material-ui/core/Container";

import scss from "./CookieConsentBanner.module.scss";
import withOrg from "Hoc/withOrg";
import withMobile from "Hoc/withMobile";
import dCookieConsentToOptional from "Dispatchers/dCookieConsentToOptional";
import withCookieConsent from "Hoc/withCookieConsent";

const CookieConsentBanner = props => {

	/**
	 * Render Cookie Consent banner
	 */
	const renderBanner = () => {
		return (
			<div className={scss.root}>
				<Container maxWidth="lg">
					<Flex
						alignItems="center"
						className={scss.alert}
						columnar={!(props.isMobile)}
						justifyContent="space-between">
						<Flex>
							<String bold={true} str={`Cookies on the ${props.org?.NameTrade} website`} variant="h6" />
							<String str="This site uses cookies. Some cookies are essential and required for the website to function correctly. There are also some optional cookies which help us monitor the performance of our marketing. You can choose to accept all cookies, or essential cookies only." />
							<Link color="inherit" label="Cookies Policy" uri={props.org?.CookiesPolicyUrl} />
						</Flex>
						<Flex>
							<Button color="secondary" className={scss.button} label="Accept All Cookies" onClick={() => dCookieConsentToOptional(true)} />
							<Button color="secondary" className={scss.button} label="Accept Essential Cookies Only" onClick={() => dCookieConsentToOptional(false)} />
						</Flex>
					</Flex>
				</Container>
			</div>
		);
	};

	/**
	 * Render Empty
	 */
	const renderEmpty = () => <></>;

	return (props.cookieConsentRequired && props.cookieConsentToOptional === null) ? renderBanner() : renderEmpty();

};

export default withCookieConsent(withMobile(withOrg(CookieConsentBanner)));
