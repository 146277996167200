import "./index.scss";
import "array-flat-polyfill";
import api from "./api.js";
import {Api, MinorCurrencyRatio} from "@hps/hops-sdk-js";
import App from "App/App.js";
import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";

/**
 * Sentry integration
 */
if (process.env.REACT_APP_SENTRY_DSN) {
	Sentry.init({
		dsn: process.env.REACT_APP_SENTRY_DSN,
		release: process.env.REACT_APP_APP
	});
}

/**
 * Development override for HOPS API base URL
 */
if (process.env.REACT_APP_HOPS_API_BASE_URL) {
	api.API_URI = process.env.REACT_APP_HOPS_API_BASE_URL;
}

/**
 * Wire up `@hps/hops-sdk-js`
 */
Api.getHeaders = () => api.headers;
Object.defineProperty(Api, "baseURL", {get: () => api.base});

/**
 * Localisation
 */
Object.defineProperty(Api, "locale", {get: () => "en-gb"});
Object.defineProperty(Api, "currencyUnit", {get: () => "GBP"});
Object.defineProperty(Api, "minorUnitDivisor", {get: () => MinorCurrencyRatio.Two});

/**
 * Render!
 */
ReactDOM.render(<App />, document.getElementById("react"));
