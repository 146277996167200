import React from "react";
import withMobile from "Hoc/withMobile.js";
import withOrg from "Hoc/withOrg.js";
import UiContainer from "./UiContainer";
import UiView from "./UiView";
import Routes from "Resources/Routes.json";
import {Avatar, makeStyles, Tooltip} from "@material-ui/core";
import String from "Components/String";
import Flex from "Components/Flex";
import Image from "Components/Image";

import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import PhoneIcon from "@material-ui/icons/Phone";
import LanguageIcon from "@material-ui/icons/Language";
import Link from "Components/Link";
import withFeatures from "Hoc/withFeatures";
import withStrings from "Hoc/withStrings";
import GiftCardRedeemCard from "GiftCards/GiftCardRedeemCard";
import {Api, Localisation} from "@hps/hops-sdk-js";
import dCookieConsentToOptional from "Dispatchers/dCookieConsentToOptional";
import withCookieConsent from "Hoc/withCookieConsent";

/**
 * UI footer
 */
const UiFooter = ({cookieConsentRequired, isFeatureEnabled, isMobile, org, strings}) => {

	const useStyles = makeStyles(theme => ({
		indexText: {
			maxWidth: "50%"
		},
		root: {
			color: theme.palette.text.secondary
		},
		small: {
			width: theme.spacing(1.5),
			height: theme.spacing(1.5)
		},
		medium: {
			width: theme.spacing(2),
			height: theme.spacing(2)
		}
	}));

	const classes = useStyles();

	const acceptedCards = [
		{
			Label: "American Express",
			Icon: "/assets/cards/american_express.png"
		},
		{
			Label: "Japan Credit Bureau (JCB)",
			Icon: "/assets/cards/jcb.png"
		},
		{
			Label: "Mastercard",
			Icon: "/assets/cards/mastercard2.png"
		},
		{
			Label: "Maestro",
			Icon: "/assets/cards/maestro2.png"
		},
		{
			Label: "Visa",
			Icon: "/assets/cards/visa.png"
		}
	];

	const handlePhoneClick = () => {
		if (typeof window !== "undefined") {
			window.location.href = `tel:${org.Details?.Phone?.trim()}`;
		}
	};

	return (
		<UiView className={classes.root} gap={0}>
			<UiContainer
				alignItems="flex-start"
				singleColumn={true}>

				<Flex gap={2}>

					<Flex
						columnar={!isMobile}
						justifyContent="space-between">

						<Flex className={!isMobile ? classes.indexText : undefined}>
							<String
								str={org.NameTrade}
								variant="h5" />
							<String str={strings.index} />

							<Flex columnar={!isMobile} gap={!isMobile ? 4 : 2}>
								{org.Details?.Address &&
								<Flex
									columnar={true}
									gap={0.5}>
									<LocationOnIcon />
									<String
										gap={0.1}
										str={
											[
												org.Details.Address.Address,
												org.Details.Address.City,
												org.Details.Address.County,
												org.Details.Address.Postcode,
												org.Details.Address.Country
											]
										} />
								</Flex>
								}

								{org.Details?.Phone &&
								<Flex
									columnar={true}
									gap={0.5}>
									<PhoneIcon />
									<Link label={org.Details.Phone} onClick={handlePhoneClick} />
								</Flex>
								}
							</Flex>

						</Flex>

						<Flex columnar={!isMobile} gap={isMobile ? 1 : 2}>
							<Link
								label="Home"
								uri={"/"} />
							{org?.NavigationLinks?.map((link, key) => (
								<Link
									key={key}
									label={link.Label}
									uri={link.Uri} />
							))}
							<Link
								label="Members' Area"
								uri={Routes.account} />
						</Flex>

					</Flex>

					<Flex
						columnar={true}
						justifyContent="center">
						{isFeatureEnabled("GiftCardRedemption") && <GiftCardRedeemCard color="inherit" withCard={false} />}
					</Flex>

					<Flex
						columnar={!isMobile}
						justifyContent="space-between">
						<Flex
							justifyContent={isMobile ? "center" : "flex-end"}>
							<Flex columnar={true} justifyContent={isMobile ? "center" : "flex-start"}>
								{org.Details?.Website && <Link color="textSecondary" uri={`https://${org.Details?.Website}`}><LanguageIcon className={classes.medium} /></Link>}
								{org.Details?.Facebook && <Link color="textSecondary" uri={`https://www.facebook.com/${org.Details?.Facebook}/`}><FacebookIcon className={classes.medium} /></Link>}
								{org.Details?.Instagram && <Link color="textSecondary" uri={`https://www.instagram.com/${org.Details?.Instagram}/`}><InstagramIcon className={classes.medium} /></Link>}
								{org.Details?.Twitter && <Link color="textSecondary" uri={`https://x.com/${org.Details?.Twitter}`}><TwitterIcon className={classes.medium} /></Link>}
							</Flex>
							<Flex columnar={true} justifyContent={isMobile ? "center" : "flex-start"}>
								{org.PrivacyPolicyUrl && <Link label={`Privacy Policy`} uri={org.PrivacyPolicyUrl} />}
								{cookieConsentRequired && <Link label="Cookie Preferences" onClick={() => dCookieConsentToOptional(null)} />}
							</Flex>
						</Flex>
						<Flex
							columnar={true}
							justifyContent={isMobile ? "center" : "flex-end"}>
							<Link color="inherit" uri="https://www.hops.org.uk">

								<Flex
									alignItems="center"
									columnar={true}
									justifyContent={isMobile ? "center" : "flex-end"}
									gap={0.5}>
									<Avatar
										alt="HOPS"
										className={classes.small}
										src="/assets/hops.png" />
									<String str="Powered by HOPS" />
								</Flex>
							</Link>
						</Flex>

						<Flex gap={0.5}>
							<Flex
								alignItems="center"
								columnar={true}
								justifyContent={isMobile ? "center" : "flex-end"}>
								<Avatar
									alt={`${Api.currencyUnit} ${Localisation.getCurrencySymbol()}`}
									className={classes.small}
									src={`/assets/flags/${Api.locale}.svg`} />
								<String
									str={`${Api.currencyUnit} ${Localisation.getCurrencySymbol()}`} />
							</Flex>
							<Flex
								alignItems="center"
								columnar={true}
								justifyContent={isMobile ? "center" : "flex-end"}>
								{acceptedCards.map((card, key) => <Tooltip
									arrow={true}
									key={key}
									title={card.Label}>
									<div>
										<Image
											alt={card.Label}
											imgClassName={classes.medium}
											noError={true}
											noLoader={true}
											objectFit="contain"
											src={card.Icon} />

									</div>
								</Tooltip>)}
							</Flex>
						</Flex>
					</Flex>
				</Flex>

			</UiContainer>
		</UiView>
	);

};

export default withCookieConsent(withFeatures(withMobile(withOrg(withStrings(UiFooter)))));
