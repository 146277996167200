import React from "react";
import {connect} from "react-redux";

export default Component => {

	const wrapper = props => <Component
		{...props}
		cookieConsentRequired={!!(props.org.GoogleTagManagerId)} />;

	return connect(({org, cookieConsentToOptional}) => ({org, cookieConsentToOptional}))(wrapper);
};
