import React from "react";
import AddedToBasketOverlay from "Checkout/AddedToBasketOverlay.js";
import AppService from "Services/AppService.js";
import BasketExpirationInterstitial from "Checkout/BasketExpirationInterstitial.js";
import ErrorGate from "Components/ErrorGate.js";
import Flex from "Components/Flex.js";
import Helmet from "react-helmet";
import SeatReservationDialog from "SeatReservation/SeatReservationDialog.js";
import SuspenseGate from "App/SuspenseGate.js";
import UiBanners from "./UiBanners.js";
import UiBar from "./UiBar.js";
import UiLoader from "./UiLoader.js";
import UiRouter from "./UiRouter.js";
import UiSuspense from "./UiSuspense.js";
import withMobile from "Hoc/withMobile.js";
import withOrg from "Hoc/withOrg.js";
import withTheme from "Hoc/withTheme.js";
import {connect} from "react-redux";
import * as Sentry from "@sentry/react";
import UiFooter from "./UiFooter.js";

import {makeStyles} from "@material-ui/core";
import CookieConsentBanner from "./CookieConsent/CookieConsentBanner.js";

/**
 * UI base
 */
const Ui = props => {

	const useStyles = makeStyles(theme => {
		return {
			root: {
				background: theme.palette.background.content
			}
		};
	});

	const classes = useStyles();

	const renderMain = () => {
		return (
			<Sentry.ErrorBoundary fallback={props => <ErrorGate notFullHeight={true} {...props} />}>
				<SuspenseGate>
					<React.Suspense fallback={<UiSuspense />}>
						<Helmet>
							<title>{props.org?.NameTrade}</title>
							<meta name="description" content={`Welcome to ${props.org?.NameTrade}!`} />
							<meta name="theme-color" content={(props.theme?.AccentColour || "#299c39")} />
							<link rel="shortcut icon" type="image/png" href={AppService.getRegistrationFaviconUri()} />
							<link rel="apple-touch-icon" href={AppService.getRegistrationFaviconUri()} />
						</Helmet>
						<UiRouter />
					</React.Suspense>
				</SuspenseGate>
			</Sentry.ErrorBoundary>
		);
	};

	return (
		<Flex gap={(!props.isMobile ? 2 : 1)}>
			<UiBar />
			<div className={classes.root}>
				<Flex
					alignItems="center"
					mt={(props.isMobile ? 8 : 12)}>
					<UiBanners />
					<CookieConsentBanner />
					{(props.ready && renderMain())}
					<AddedToBasketOverlay />
					<BasketExpirationInterstitial />
					<SeatReservationDialog />
				</Flex>
			</div>
			<Flex alignItems="center">
				<UiFooter />
			</Flex>
			<UiLoader visible={props.loading} />
		</Flex>
	);

};

export default connect(({loading}) => ({loading}))(withMobile(withOrg(withTheme(Ui))));
