import Routes from "Resources/Routes.json";
import {F12Icon, DonationsIcon, MembershipsIcon, SharesIcon, ShopIcon, TicketsIcon} from "Resources/Icons.js";

/** Order is important - index will redirect to first enabled feature */

export default [
	{
		name: "Tickets",
		label: "Tickets",
		caption: () => "Purchase tickets for travel, events and experiences.",
		icon: TicketsIcon,
		uri: Routes.tickets
	},
	{
		name: "Shop",
		label: "Shop",
		caption: () => `Purchase gift cards, products and vouchers.`,
		icon: ShopIcon,
		uri: Routes.shop
	},
	{
		name: "Donations",
		label: "Donations",
		caption: () => "Purchase tickets for travel, events and experiences.",
		icon: DonationsIcon,
		uri: Routes.donations
	},
	{
		name: "Memberships",
		label: "Memberships",
		caption: org => `Purchase ${org.NameShort} Membership.`,
		icon: MembershipsIcon,
		uri: Routes.memberships
	},
	{
		name: "Shares",
		label: "Shares",
		caption: org => `Purchase shares in a ${org.NameShort} company.`,
		icon: SharesIcon,
		uri: Routes.shares
	},
	{
		name: "GiftCardRedemption",
		label: "Redeem Gift Cards",
		caption: () => "Redeem a Gift Card.",
		icon: SharesIcon,
		uri: `${Routes.giftcards}/redeem`
	},
	{
		name: "F12",
		label: "Developer tools",
		caption: () => "App developer tools.",
		icon: F12Icon,
		uri: "/?f12=true"
	}
];
